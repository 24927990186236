$(document).ready(function () {
  new Swiper('.brands-slider__swiper-container', {
    slidesPerView: 'auto',
    spaceBetween: 64,
    navigation: {
      nextEl: ".brands-slider__next",
      prevEl: ".brands-slider__prev",
    },
  });

  new Swiper('.banners-slider__swiper-container', {
    slidesPerView: 1,
    speed: 600,
    navigation: {
      nextEl: ".banners-slider__next",
      prevEl: ".banners-slider__prev",
    },
  });

  $('button.filter__section-head').click(function (e) {
    e.preventDefault();
    $(this).closest('.filter__section').toggleClass('is-open');
  });

  $('[data-fancybox]').fancybox({
    toolbar  : false,
	  smallBtn : true,
    infobar: false,
  });

  if ($('.price-slider').length) {
    initPriceSlider();
  }

  if ($('.products-slider').length) {
    initProductsSlider();
  }

  if ($('.gallery').length) {
    initGallery();
  }

  initSearchbar();
  initMobileMenu();
  initTabs();
  initPhotosSlider();
  initOrderCard();
  initProductOptions();
  initViewSettings();
  initFilterWindow();
  initModals();
  initAccordion();
  initFileInput();
  initCompareSlider();
  initDropdown();
});

function initPriceSlider() {
  const slider = $('.price-slider__slider');
  const min = parseInt(slider.attr('data-min'));
  const max = parseInt(slider.attr('data-max'));
  const valuesLabels = [

  ];
  const minLabel = $('.price-slider__labels span:nth-child(1)');
  const maxLabel = $('.price-slider__labels span:nth-child(2)');

  noUiSlider.create(slider[0], {
    range: {
      'min': min,
      'max': max,
    },
    start: [min, max],
    step: 1,
    connect: true,
  });

  slider[0].noUiSlider.on('update', function (values, handle) {
    minLabel.html(parseInt(values[0]));
    maxLabel.html(parseInt(values[1]));
  });
}

function initProductsSlider() {
  new Swiper('.products-slider__swiper-container', {
    slidesPerView: 2,
    navigation: {
      nextEl: ".products-slider__next",
      prevEl: ".products-slider__prev",
    },
    breakpoints: {
      1200: {
        slidesPerView: 4,
      },
    },
  });
}

function initSearchbar() {
  $('.js-toggle-searchbar').click((e) => {
    e.preventDefault();
    $('body').toggleClass('searchbar-active');
  });
}

function initMobileMenu() {
  $('.js-toggle-mobile-menu').click((e) => {
    e.preventDefault();
    $('body').toggleClass('mobile-menu-active');
  });
}

function initTabs() {
  $('.tabs__nav a').click(function(e) {
    e.preventDefault();
    if (!$(this).hasClass('is-active')) {
      $(this).addClass('is-active').siblings().removeClass('is-active');
      $($(this).attr('href')).addClass('is-active').siblings().removeClass('is-active');
    }
  });
}

function initGallery() {
  var swiper = new Swiper(".gallery__thumbs-swiper-container", {
    spaceBetween: 13,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,
    breakpoints: {
      768: {
        spaceBetween: 24,
      },
      1200: {
        spaceBetween: 30,
      },
    },
  });
  var swiper2 = new Swiper(".gallery__main-swiper-container", {
    spaceBetween: 0,
    navigation: {
      nextEl: ".gallery__next",
      prevEl: ".gallery__prev",
    },
    thumbs: {
      swiper: swiper,
    },
  });
}

function initPhotosSlider() {
  if ($('.photos-slider').length) {
    const slidesPrevView = $('.photos-slider').attr('data-slides');
    const slidesGap = $('.photos-slider').attr('data-gap');

    new Swiper('.photos-slider__swiper-container', {
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: ".photos-slider__next",
        prevEl: ".photos-slider__prev",
      },
      pagination: {
        el: '.photos-slider__dots',
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 2,
        },
        1200: {
          slidesPerView: parseInt(slidesPrevView) || 4,
          spaceBetween: parseInt(slidesGap) || 0,
        },
      },
    });
  }
}

function initOrderCard() {
  $('.order-card__show-all').click(function(e) {
    e.preventDefault();
    $(this).closest('.order-card__items').toggleClass('all-visible');
  });
}

function initProductOptions() {
  $('.prod__options-toggle').click(function(e) {
    e.preventDefault();
    $(this).closest('.prod__options').toggleClass('is-active');
  });
}

function initViewSettings() {
  const target = $('.view-settings__btns').attr('data-target');

  $('.js-view-settings button').click(function(e) {
    e.preventDefault();

    if (!$(this).hasClass('is-active')) {
      const view = $(this).attr('data-view');
      $(this).addClass('is-active').siblings().removeClass('is-active');
      $(target).attr('data-view', view);
    }
  });
}

function initFilterWindow() {
  $('.js-toggle-filters').click(function(e) {
    e.preventDefault();
    $('.filter-window').toggleClass('is-active');
    $('body').toggleClass('filter-window-active');
  });
}

function initModals() {
  $('.js-open-modal').click(function(e) {
    e.preventDefault();
    $('body').addClass('modal-active');
    $($(this).attr('data-modal')).addClass('is-active');
  });

  $('.js-close-modal').click(function(e) {
    e.preventDefault();
    $('body').removeClass('modal-active');
    $(this).closest('.modal').removeClass('is-active');
  });
}

function initAccordion() {
  $('.accordion__toggle').click(function(e) {
    e.preventDefault();
    $(this).closest('.accordion__item').toggleClass('is-active').siblings().removeClass('is-active');
  });
}

function initFileInput() {
  $('.file-input__input').change(function(e){
    // const value = $(this).val();
    const value = e.target.files[0].name;
    $(this).closest('.file-input').addClass('selected').find('.file-input__value span').text(value);
  });

  $('.file-input__clear').click(function(e) {
    e.preventDefault();
    $(this).closest('.file-input').removeClass('selected').find('.file-input__input').val('');
  });
}

function initCompareSlider() {
  if ($('.compare-slider').length) {
    new Swiper('.compare-slider__swiper-container', {
      slidesPerView: 2,
      spaceBetween: -1,
      navigation: {
        nextEl: '.compare-slider__next',
        prevEl: '.compare-slider__prev',
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
        },
      },
    });
  }
}

function initDropdown() {
  $('.dropdown__toggle').click(function(e) {
    e.preventDefault();
    $(this).closest('.dropdown').toggleClass('is-active');
  });

  $(document).click(function(event) { 
    const $target = $(event.target);
    if(!$target.closest('.dropdown.is-active').length) {
      $('.dropdown.is-active').removeClass('is-active');
    }        
  });

  $('.dropdown__option').click(function(e) {
    e.preventDefault();
    $(this).addClass('is-active').siblings().removeClass('is-active');
    $(this).closest('.dropdown').removeClass('is-active').find('.dropdown__toggle').html($(this).attr('data-value'));
  });
}